import { API_BASE_URL } from 'constants/api.constant'
import ApiService from './ApiService'
import axios from 'axios'

export async function apiSignIn (data) {
    debugger
    return axios.post(`${API_BASE_URL}Admin/Login`, {
        email: data.userName,
        password: data.password,
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}
